import React from 'react';
import { Link, graphql } from 'gatsby';

import {
    IntroHolder,
    TwoColumns,
    Sidebar,
    Reference,
    Content,
    IntroQuote,
} from '@bahaide/shared/components/InnerPage';

import InnerPage from '../../../../components/InnerPage';

const Trainingsinstitut = ({ data }) => (
    <InnerPage title="Das Trainingsinstitut" description="">
        <IntroHolder
            title="Das Trainingsinstitut"
            subtitle="Über Fähigkeiten nachdenken und sie entwickeln"
            picture={data.introImage}
            breadcrumbs={{
                grandparent: 'Gemeinsames Handeln',
                parent: 'Gemeinsames Lernen',
                'current-item': 'Das Trainingsinstitut',
            }}
        >
            <IntroQuote>
                Der ist wirklich ein Mensch, der sich dem Dienst am ganzen
                Menschengeschlecht hingibt.
            </IntroQuote>
        </IntroHolder>

        <TwoColumns>
            <Content>
                <p>
                    Der erhoffte, positive Wandel in der Gesellschaft kann nur
                    durch die Entdeckung des Potenzials und die
                    Weiterentwicklung von Fähigkeiten erreicht werden, um den
                    unzähligen, vielfältigen Herausforderungen und Aufgaben
                    begegnen zu können. Es bedarf einer wachsenden Anzahl von
                    Menschen, die dieses Ziel verfolgen.
                </p>
                <div>
                    <p>
                        Um vielen Menschen die Möglichkeit zu geben, sich
                        hierfür in ihrem Umfeld zu engagieren, hat die
                        Bahá’í-Gemeinde ein Trainingsinstitut entwickelt, das
                        eine Kursfolge beinhaltet. Durch die aus den
                        Bahá’í-Schriften gewonnenen Einsichten werden eigene
                        Eigenschaften, Haltungen und praktische Fähigkeiten
                        vertieft und trainiert. Dies soll jeden Einzelnen dabei
                        unterstützen, sich zum Wohle seines Umfeldes
                        einzubringen. Man kann diese Kurse (Studienkreise) als
                        ein sich immer weiter ausdehnendes Gespräch zwischen
                        Menschen verstehen, die sich um den geistigen und
                        materiellen Fortschritt der Gesellschaft mühen.
                    </p>
                    <p>
                        Ein Studienkreis besteht aus mehreren Teilnehmern, der
                        von einem Tutor, oder einer bereits mit dem Material
                        vertrauten Person, geleitet wird. Die Atmosphäre ist
                        eine des{' '}
                        <Link to="/gemeinsames-handeln/gemeinsames-lernen/">
                            gemeinsamen Lernens
                        </Link>
                        , bei der jeder selbst Verantwortung für sein Lernen
                        übernimmt und zur Gruppe beiträgt. Gegenseitige
                        Unterstützung im Verstehen der Texte, ebenso wie in
                        ihrer Umsetzung in der Arena des Handelns sind eine
                        Selbstverständlichkeit für alle.
                    </p>
                    <p>
                        <img
                            alt=""
                            src={
                                data.dasTrainingsinstitutCollage.childImageSharp
                                    .fluid.src
                            }
                            className="aligncenter"
                        />
                    </p>
                    <p>
                        Die Inhalte der Kurse beschäftigen sich mit vielen
                        Fragen, die gemeinsam erforscht werden. Wie können
                        geistige Eigenschaften mit unserem alltäglichen Handeln
                        verbunden werden? Wie können wir die Bande der
                        Freundschaft in unserem Umfeld stärken? Wie können wir
                        uns der geistigen und moralischen Erziehung von Kindern
                        widmen? Wie können junge Menschen darin bestärkt werden,
                        die Kräfte ihres Umfeldes zu analysieren, und gerüstet
                        werden, Entscheidungen zu treffen, die zur materiellen
                        und geistigen Entwicklung beitragen? Wie können wir alle
                        diese Fähigkeiten aufbauen und die Möglichkeit
                        ergreifen, weitere Freunde und Bekannte im gemeinsamen
                        Handeln und Aufbau von Fähigkeiten zu begleiten? Dies
                        sind nur einige von vielen Fragen, denen jeder
                        Teilnehmer auf seiner Reise durch das Kursmaterial
                        begegnet und über die ein offener, freudvoller und
                        harmonischer Austausch stattfindet, der den Weg für
                        gemeinsames Handeln ebnet.
                    </p>
                </div>
                <Reference>
                    <p>1. Bahá’u’lláh, Botschaften aus 'Akká 11:13</p>
                </Reference>
            </Content>
            <aside>
                <div
                    className="blockquote-text"
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        backgroundColor: 'rgb(131, 34, 43)',
                        fontSize: '16px',
                        padding: '15px',
                        color: 'white',
                    }}
                >
                    <h2>Nachdenken über das geistige Leben</h2>
                    <p>
                        Sie sind herzlich eingeladen,{' '}
                        <a
                            href="https://www.ruhi.org/en/materials-in-your-language/de_main"
                            target="_blank"
                            rel="noopener"
                        >
                            hier
                        </a>{' '}
                        die neueste Version des ersten Buches unserer Kursfolge,
                        Nachdenken über das geistige Leben, herunterzuladen. Das
                        Buch wird jedem zur Verfügung gestellt, der sich an
                        einem globalen Gespräch über die Besserung der Welt
                        beteiligen möchte, das durch Bahá'í-Gemeinden initiiert
                        wird.
                    </p>
                    <p>
                        Alle sind eingeladen, das Buch zu studieren und seinen
                        Inhalt mit Freunden zu besprechen. Über diese{' '}
                        <a href="https://www.bahai.de/bahai-kennenlernen/">
                            Liste
                        </a>{' '}
                        können Sie mit einer Bahá'í-Gemeinde in Ihrer Nähe
                        Kontakt aufnehmen, wenn Sie das Buch in einer Gruppe
                        studieren oder mit anderen Büchern aus unserer Kursfolge
                        fortfahren möchten.
                    </p>
                    <p>
                        Wir bitten lediglich darum, dass weder das Buch noch
                        Auszüge davon auf andere Internetseiten oder
                        Cloud-Speicher hochgeladen werden oder dass es für
                        kommerzielle Zwecke oder in einer Weise verwendet wird,
                        die dem Geist des Materials selbst zuwiderläuft oder
                        seine Absicht falsch darstellt.
                    </p>
                </div>
            </aside>
        </TwoColumns>
    </InnerPage>
);

export default Trainingsinstitut;

export const pageQuery = graphql`
    query {
        introImage: file(
            relativePath: { eq: "das-trainingsinstitut-feature.jpg" }
        ) {
            ...fluidImage
        }
        dasTrainingsinstitutCollage: file(
            relativePath: { eq: "das-trainingsinstitut-img-1.jpg" }
        ) {
            ...fluidImage
        }
    }
`;
